
export const environment = {
production: true, 
imageKitUrl: "https://ik.imagekit.io/wgucm6iv5/staging", 
appVersion: "v717demo1", 
firebaseStorageUrl: "https://firebasestorage.googleapis.com/v0/b/flipteria-customer-production.appspot.com/o", 
USERDATA_KEY: "authf649fc9a5f55", 
isMockEnabled: "true", 
apiUrl: "api", 
apiUrlNew: "https://api.staging.flipteria.lk/api/v2", 
customerPortlUrl: "https://staging.flipteria.lk", 
firebaseConfig: { apiKey : "AIzaSyCGMEgzLeUjEyn4Oxbr1_1Dx-xX8eEU9aU", 
authDomain : "flipteria-customer-production.firebaseapp.com", 
projectId : "flipteria-customer-production", 
storageBucket : "flipteria-customer-production.appspot.com", 
messagingSenderId : "765783674583", 
appId : "1:765783674583:web:314b6bf831c31f7f35aa92", 
measurementId : "G-SLKDWF5D6X", 
},bunnyConfig: { apiUrl : "https://sg.storage.bunnycdn.com", 
storageZoneName : "flipteriasecond", 
accessKey : "5d6b1014-6123-450b-83ee91d2d051-3c9d-4d89", 
path : "/staging/product_images", 
},azureBlobUrl: "https://flipteria.blob.core.windows.net", 
test: "prod", 
sellerPortalUrl: "https://sellercenter.staging.flipteria.lk", 
sellerImpersonateUrl: "https://sellercenter-imp.staging.flipteria.lk/auth/session/", 
applicationServerPublicKey: "BFsZ8GeWzpZTHboaxFIHExblP8wLzADhbfNw8cIMVpLlK-qoXafaFDJNSYzLk53VtKQYf9UzAF4U1wCoQZQWws4", 
};